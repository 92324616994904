import React, { useState, useEffect } from 'react';
import { LogOut, Home, Users, Download } from 'lucide-react';
import { auth } from './lib/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ColorWheel } from './components/ColorWheel';
import { NumberSelector } from './components/NumberSelector';
import { JournalEntry } from './components/JournalEntry';
import { Auth } from './components/Auth';
import { Archive } from './components/Archive';
import { VideoTraining } from './components/VideoTraining';
import { Tithe } from './components/Tithe';
import { Footer } from './components/Footer';
import { FirstTimeModal } from './components/FirstTimeModal';
import type { ColorOption } from './types';

function App() {
  const [user, setUser] = useState(auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState<'initial' | 'color' | 'number' | 'journal'>('initial');
  const [selectedColor, setSelectedColor] = useState<ColorOption | null>(null);
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);
  const [showArchive, setShowArchive] = useState(false);
  const [showVictories, setShowVictories] = useState(false);
  const [showTraining, setShowTraining] = useState(false);
  const [showTithe, setShowTithe] = useState(false);
  const [threadTitle, setThreadTitle] = useState('');
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      if (user && !localStorage.getItem('hasSeenFirstTimeModal')) {
        setShowFirstTimeModal(true);
        localStorage.setItem('hasSeenFirstTimeModal', 'true');
      }
    });
    return unsubscribe;
  }, []);

  const handleSaveComplete = () => {
    setStep('initial');
    setSelectedColor(null);
    setSelectedNumber(null);
    setThreadTitle('');
  };

  const resetView = () => {
    setShowArchive(false);
    setShowVictories(false);
    setShowTraining(false);
    setShowTithe(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Auth />;
  }

  if (showFirstTimeModal) {
    return <FirstTimeModal onClose={() => setShowFirstTimeModal(false)} />;
  }

  const renderHeader = () => (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex flex-wrap gap-4 items-center justify-between">
        <div className="flex items-center gap-3">
          <img 
            src="https://imgur.com/47w7oTV.png" 
            alt="H.E.A.L. Logo" 
            className="h-8 w-auto"
          />
          <h1 className="text-2xl font-bold text-gray-900">
            {showArchive ? 'Archive' : 
             showVictories ? 'Victory Room' :
             showTraining ? 'Training Videos' :
             showTithe ? 'Tithe' : 'H.E.A.L.'}
          </h1>
        </div>
        <div className="flex flex-wrap gap-3">
          {(showArchive || showVictories || showTraining || showTithe) && (
            <button
              onClick={resetView}
              className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
            >
              Back to Journal
            </button>
          )}
          {!showArchive && (
            <button
              onClick={() => {
                resetView();
                setShowArchive(true);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              View Archive
            </button>
          )}
          {!showVictories && (
            <button
              onClick={() => {
                resetView();
                setShowVictories(true);
              }}
              className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700"
            >
              Victory Room
            </button>
          )}
          {!showTraining && (
            <button
              onClick={() => {
                resetView();
                setShowTraining(true);
              }}
              className="px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-cyan-700"
            >
              Training Videos
            </button>
          )}
          {!showTithe && (
            <button
              onClick={() => {
                resetView();
                setShowTithe(true);
              }}
              className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
            >
              Tithe
            </button>
          )}
          <button
            onClick={() => signOut(auth)}
            className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 flex items-center gap-2"
          >
            <LogOut size={18} />
            Sign Out
          </button>
        </div>
      </div>
    </header>
  );

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {renderHeader()}

      <main className="flex-1">
        {showArchive && <Archive userId={user.uid} />}
        {showVictories && <Archive userId={user.uid} showVictories={true} />}
        {showTraining && <VideoTraining />}
        {showTithe && <Tithe />}
        
        {!showArchive && !showVictories && !showTraining && !showTithe && (
          <div className="max-w-4xl mx-auto px-4 py-8">
            {step === 'initial' && (
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-xl shadow-lg">
                  <img
                    src="https://imgur.com/wQGWjEq.png"
                    alt="H.E.A.L. Book"
                    className="w-48 mx-auto mb-6"
                  />
                  <h2 className="text-xl font-semibold mb-4">Start Your Healing Journey</h2>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Give this healing thread a title..."
                      value={threadTitle}
                      onChange={(e) => setThreadTitle(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                    />
                    <button
                      onClick={() => threadTitle.trim() && setStep('color')}
                      disabled={!threadTitle.trim()}
                      className="w-full py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Begin Thread
                    </button>

                    <div className="pt-4 flex flex-wrap justify-center gap-4">
                      <a
                        href="https://www.thereisnothingbutgod.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 bg-rose-600 text-white rounded-md hover:bg-rose-700 transition-colors"
                      >
                        <Home className="w-4 h-4" />
                        Ministry Home
                      </a>
                      <a
                        href="https://www.allowministries.com/communities/groups/allow-ministries-321/home?invite=67361fc92f24cc2e2bef1da6"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-cyan-700 transition-colors"
                      >
                        <Users className="w-4 h-4" />
                        Private Community
                      </a>
                      <a
                        href="https://drive.google.com/file/d/1Xre7gvpvFXPctZAsanUbBf5llgwt2PxS/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors"
                      >
                        <Download className="w-4 h-4" />
                        Download Book
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 'color' && (
              <div className="bg-white p-6 rounded-xl shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Select Your Color</h2>
                <ColorWheel onColorSelect={setSelectedColor} selectedColor={selectedColor} />
                {selectedColor && (
                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={() => setStep('number')}
                      className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            )}

            {step === 'number' && (
              <div className="bg-white p-6 rounded-xl shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Choose Your Number</h2>
                <NumberSelector
                  onNumberSelect={setSelectedNumber}
                  selectedNumber={selectedNumber}
                  onContinue={() => setStep('journal')}
                />
              </div>
            )}

            {step === 'journal' && selectedColor && selectedNumber && (
              <JournalEntry
                userId={user.uid}
                threadTitle={threadTitle}
                color={selectedColor}
                number={selectedNumber}
                onSaveComplete={handleSaveComplete}
              />
            )}
          </div>
        )}
      </main>

      <Footer />
    </div>
  );
}

export default App;