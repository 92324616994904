import React, { useState, useEffect, useMemo } from 'react';
import { format, fromUnixTime } from 'date-fns';
import { Calendar, Search, Loader2, AlertCircle, Trash2, Trophy } from 'lucide-react';
import { fetchEntries, deleteVictory, deleteJournalEntry } from '../lib/firebase';
import type { JournalEntry } from '../types';

interface ArchiveProps {
  userId: string;
  showVictories?: boolean;
}

function formatFirestoreDate(timestamp: any): string {
  if (!timestamp) return '';
  
  try {
    if (timestamp?.seconds) {
      return format(fromUnixTime(timestamp.seconds), 'PPP');
    }
    
    if (typeof timestamp === 'string') {
      return format(new Date(timestamp), 'PPP');
    }
    
    if (timestamp instanceof Date) {
      return format(timestamp, 'PPP');
    }
    
    return '';
  } catch (error) {
    console.error('Date formatting error:', error);
    return '';
  }
}

export function Archive({ userId, showVictories = false }: ArchiveProps) {
  const [entries, setEntries] = useState<JournalEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const loadEntries = async () => {
      try {
        setLoading(true);
        const data = await fetchEntries(userId);
        setEntries(data);
      } catch (err: any) {
        console.error('Error fetching entries:', err);
        setError('Failed to load entries');
      } finally {
        setLoading(false);
      }
    };

    loadEntries();
  }, [userId]);

  const handleDelete = async (entryId: string) => {
    if (!entryId) return;
    
    try {
      setIsDeleting(true);
      await deleteJournalEntry(entryId);
      setEntries(entries.filter(entry => entry.id !== entryId));
      setShowDeleteConfirm(null);
    } catch (err: any) {
      setError('Failed to delete entry');
    } finally {
      setIsDeleting(false);
    }
  };

  const filteredEntries = useMemo(() => {
    return entries.filter(entry => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        entry.threadTitle?.toLowerCase().includes(searchLower) ||
        entry.content?.toLowerCase().includes(searchLower) ||
        entry.beliefs?.some(belief => 
          belief.belief.toLowerCase().includes(searchLower) ||
          belief.howIsItALie.toLowerCase().includes(searchLower)
        );

      if (showVictories) {
        return entry.victories && entry.victories.length > 0 && matchesSearch;
      }

      return matchesSearch;
    });
  }, [entries, searchTerm, showVictories]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="w-12 h-12 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="flex items-center gap-2 text-red-600 bg-red-50 p-4 rounded-lg">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <div className="mb-8 flex items-center justify-between">
        <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
          {showVictories ? (
            <>
              <Trophy className="w-6 h-6 text-yellow-500" />
              Victory Room
            </>
          ) : (
            <>
              <Calendar className="w-6 h-6" />
              Your Journey Archive
            </>
          )}
        </h2>
        <div className="relative">
          <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder={showVictories ? "Search victories..." : "Search entries..."}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
      </div>

      <div className="space-y-6">
        {filteredEntries.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-500">
              {showVictories
                ? 'No victories found. Start celebrating your wins!'
                : 'No entries found. Start your healing journey today!'}
            </p>
          </div>
        ) : (
          filteredEntries.map(entry => (
            <div
              key={entry.id}
              className="bg-white p-6 rounded-lg shadow-sm space-y-4 relative group"
            >
              {showDeleteConfirm === entry.id && (
                <div className="absolute top-2 right-2 flex items-center gap-2">
                  <button
                    onClick={() => handleDelete(entry.id)}
                    disabled={isDeleting}
                    className="px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50 flex items-center gap-1"
                  >
                    {isDeleting ? (
                      <>
                        <Loader2 className="w-4 h-4 animate-spin" />
                        Deleting...
                      </>
                    ) : (
                      'Confirm'
                    )}
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirm(null)}
                    disabled={isDeleting}
                    className="px-3 py-1 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300"
                  >
                    Cancel
                  </button>
                </div>
              )}

              {!showDeleteConfirm && (
                <button
                  onClick={() => setShowDeleteConfirm(entry.id)}
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-red-100 rounded-full"
                >
                  <Trash2 className="w-4 h-4 text-red-600" />
                </button>
              )}

              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-800">
                  {entry.threadTitle}
                </h3>
                <time className="text-sm text-gray-500">
                  {formatFirestoreDate(entry.createdAt)}
                </time>
              </div>
              
              {!showVictories && (
                <>
                  <div className="flex items-center space-x-4">
                    <div className={`w-4 h-4 rounded-full bg-${entry.color}-500`} />
                    <span className="text-sm text-gray-500">Number: {entry.number}</span>
                  </div>
                  
                  <p className="text-gray-700 whitespace-pre-wrap">{entry.content}</p>
                  
                  {entry.beliefs?.length > 0 && (
                    <div className="space-y-3">
                      <h4 className="font-medium text-gray-900">Beliefs Explored:</h4>
                      {entry.beliefs.map((belief, index) => (
                        <div key={belief.id} className="ml-4 space-y-1">
                          <p className="text-gray-700">
                            {index + 1}. {belief.belief}
                          </p>
                          <p className="text-gray-600 ml-4">
                            → {belief.howIsItALie}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              {entry.victories?.length > 0 && showVictories && (
                <div className="space-y-3">
                  {entry.victories.map((victory) => (
                    <div
                      key={victory.id}
                      className="bg-gradient-to-br from-yellow-50 to-orange-50 p-4 rounded-lg"
                    >
                      <h4 className="font-medium text-gray-900">{victory.title}</h4>
                      <p className="text-gray-700 mt-2">{victory.description}</p>
                      <div className="flex items-center justify-between mt-2 text-sm text-gray-500">
                        <span>{victory.category}</span>
                        <time>{format(new Date(victory.date), 'MMM d, yyyy')}</time>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}