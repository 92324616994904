import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';
import type { Belief, Victory, SaveResult } from '../types';

const firebaseConfig = {
  apiKey: "AIzaSyCkbYWj_l3fSK4VlksB0M7qdNr5pCi4zFw",
  authDomain: "heal-97e65.firebaseapp.com",
  projectId: "heal-97e65",
  storageBucket: "heal-97e65.firebasestorage.app",
  messagingSenderId: "530226995650",
  appId: "1:530226995650:web:f3918b074cd84123e7b65b"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const COLLECTION_NAME = 'journal_entries';

export async function saveJournalEntry(
  userId: string,
  threadTitle: string,
  color: string,
  number: number,
  content: string,
  beliefs: Belief[],
  victories: Victory[] = []
): Promise<SaveResult> {
  if (!auth.currentUser) {
    throw new Error('You must be signed in to save entries');
  }

  try {
    const entriesRef = collection(db, COLLECTION_NAME);
    const docRef = await addDoc(entriesRef, {
      userId,
      threadTitle,
      color,
      number,
      content,
      beliefs,
      victories,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return {
      success: true,
      id: docRef.id
    };
  } catch (error: any) {
    console.error('Error saving journal entry:', error);
    return {
      success: false,
      id: '',
      error: error.message
    };
  }
}

export async function fetchEntries(userId: string) {
  if (!auth.currentUser) {
    throw new Error('You must be signed in to fetch entries');
  }

  try {
    const entriesRef = collection(db, COLLECTION_NAME);
    const q = query(
      entriesRef,
      where('userId', '==', userId)
    );

    const snapshot = await getDocs(q);
    const entries = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return entries.sort((a, b) => {
      const dateA = a.createdAt?.seconds || 0;
      const dateB = b.createdAt?.seconds || 0;
      return dateB - dateA;
    });
  } catch (error: any) {
    console.error('Error fetching entries:', error);
    throw error;
  }
}

export async function deleteJournalEntry(entryId: string): Promise<void> {
  if (!auth.currentUser) {
    throw new Error('You must be signed in to delete entries');
  }

  try {
    const entryRef = doc(db, COLLECTION_NAME, entryId);
    await deleteDoc(entryRef);
  } catch (error: any) {
    console.error('Error deleting entry:', error);
    throw error;
  }
}

export async function deleteVictory(entryId: string, victoryId: string): Promise<void> {
  if (!auth.currentUser) {
    throw new Error('You must be signed in to delete victories');
  }

  try {
    const entryRef = doc(db, COLLECTION_NAME, entryId);
    const entrySnap = await getDoc(entryRef);
    
    if (!entrySnap.exists()) {
      throw new Error('Entry not found');
    }

    const entryData = entrySnap.data();
    const updatedVictories = (entryData.victories || []).filter(
      (v: Victory) => v.id !== victoryId
    );

    await updateDoc(entryRef, {
      victories: updatedVictories,
      updatedAt: serverTimestamp()
    });
  } catch (error: any) {
    console.error('Error deleting victory:', error);
    throw error;
  }
}